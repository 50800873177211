.destination {
  margin: 4rem 6rem;
  color: #2a2a2a;
}

.destination h1 {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #660000;
}

.highlighted-box {
  position: relative; /* this is crucial for the absolutely positioned child */
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 60px;
  background-color: #fff;       /* to contain floated or absolutely positioned children */
}

.first-des {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4.5rem;
}

.first-des-reverse {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: 6.5rem;
}

.des-text {
  width: 45%;
  text-align: justify;
  font-size: 1.1rem;
}

.des-text h2 {
  padding-bottom: 1rem;
  color: #660000;
  font-weight: 400;
  background-color: #ffeddd; 
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 30px;
  width: 50%;
}

.first-text {
  margin-bottom: 20px;
}

.image {
  width: 45%;
  display: flex;
  justify-content: space-between;
}

.image img {
  position: relative;
  width: 100%;
  height: 380px;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.25);
}

.picture-2 {
  position: absolute;
  top: -5%;
  right: 0;
  z-index: 1;
}

.social-icons {
  margin-top: 40px;
  display: flex;
  gap: 20px;
  justify-content: center;
}

.social-icons a {
  color: #660000; /* default icon color */
  transition: color 0.3s ease; 
}

.social-icons a:hover {
  color: #0077b5; /* change to desired hover color */
}

@media screen and (max-width: 850px) {
  .destination {
    margin: 4rem 1rem;
  }
  .first-des {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 2.5rem;
  }

  .first-des-reverse {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 2.5rem;
  }

  .des-text {
    width: 100%;
  }

  .des-text h2 {
    width: 100%;
    padding: 10px 6px;
    text-align: center;
  }

  .image {
    width: 100%;
    margin: 1.8rem 0;
  }

  .image img {
    height: 250px;
  }

  .picture-2 {
    margin-left: 10px;
  }

  .highlighted-box {
    padding: 40px;
  }
}
