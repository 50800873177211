.footer {
  background: #660000;
  color: #ffcb99;
  padding: 1rem 6rem;
}

.top {
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: space-between;
}

.top p {
  font-size: 0.7rem;
  text-align: center;
}

.top i {
  color: #ffcb99;
  font-size: 2rem;
  margin-left: 1rem;
}

.top i:hover {
  color: #86b091 ;
}

.bottom {
  padding-top: 0.5rem;
  text-align: start;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bottom div {
  display: flex;
  flex-direction: column;
}

.bottom h4 {
  font-size: 1.3rem;
  padding: 1rem 0 0.8rem 0;
}

.logo{
  display: flex;
  align-items: center;
}

.logo-image {
  width: 150px;
}

.bottom a {
  text-decoration: none;
  color: #ffeddd ;
  padding-bottom: 0.4rem;
  font-size: 1.1rem;
}

@media screen and (max-width: 850px) {
  .footer {
    padding: 1rem 1.2rem;
  }

  .footer i {
    margin: 1rem 1rem 0 0;
  }

  .bottom div {
    width: 100%;
  }

  .logo{
    display: flex;
  }
  
  .logo-image {
    width: 80px;
  }

  .top {
    flex-direction: column;
  }
  
}
