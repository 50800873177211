.office {
    margin: 4rem 6rem;
    color: #2a2a2a;
  }
  
  .office h1 {
    font-size: 3rem;
    color: #660000
  }
  
  .officecard {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
  }
  
  .o-card {
    width: 32%;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem 0.8rem;
  }
  .o-image {
    height: 200px;
    overflow: hidden;
    border-radius: 7px;
  }
  
  .o-image img {
    width: 100%;
    height: 100%;
    border-radius: 7px;
    transition: 0.3s ease-in-out;
    display: block; /* removes any default vertical space */
    margin: 0 auto; 
  }
  
  .o-card:hover img {
    transform: scale(1.3);
    overflow: hidden;
  }
  
  .o-card h4 {
    font-size: 1.3rem;
    padding: 0.9rem 0 0.2rem 0;
  }
  
  hr.rounded {
    border-top: 1px solid #bebebe;
    border-radius: 5px;
    width: 80%;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
    margin: auto;
    margin-top: 80px;
  }
  
  @media screen and (max-width: 850px) {
    .office {
      margin: 4rem 2rem;
    }
    .officecard {
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
    }
    .o-card {
      margin-bottom: 1.5rem;
      width: 100%;
    }
  }
  