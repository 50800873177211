.service {
    margin: 4rem 6rem;
    color: #2a2a2a;
  }
  
  .service h1 {
    font-size: 3rem;
    color: #660000;
  }
  
  .servicecard {
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
  }
  
  .s-card {
    display: flex;
    flex: 0 0 calc(33.3333% - 1.5rem);
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    text-align: center;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
    padding: 1.5rem 1rem;
  }
  
  .s-image {
    height: 50%;
    justify-content: center;
    align-items: stretch;
    overflow: hidden;
    border-radius: 7px;
  }
  
  .s-image img {
    width: 100px;
    height: 100px;
    border-radius: 7px;
    transition: 0.3s ease-in-out;
  }
  
  .s-card h4 {
    font-size: 1.3rem;
    margin-bottom: 10px;
    padding: 0.9rem 0 0.2rem 0;
  }
  
  .s-card .show {
    display: flex;
    margin-top: 10%;
    justify-content: center;
    text-decoration: none;
    background: #9EC1A3;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    vertical-align: middle;
    letter-spacing: 2px;
    color: #ffeddd;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);  /* New shadow */
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease; /* New transition */
    cursor: pointer;  /* Indicates clickable area */
  }
  
  /* Hover effect for the button */
  .s-card .show:hover {
    background: #86b091; 
    transform: translateY(-3px);  /* Gives a subtle lift effect */
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.12);  /* Increase shadow on hover for depth */
  }
  
  
  .show .whatsapp-icon {
    font-size: 1.2rem;
    color: #ffeddd;
    vertical-align: middle; 
    margin-right: 8px;    
  }
  
  .texts {
    display: flex;
    margin-top: 10px;
    height: 100%;
    max-width: 400px;
    text-align: center;
  }

  .texts p {
    text-indent: 8px;
  }

  .workDiv {
    margin: 4rem 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #2a2a2a;
  }

.workCard {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  max-width: 800px;
}

  @media screen and (max-width: 850px) {
    .service {
      margin: 4rem 2rem;
    }
    .servicecard {
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
    }

    .workCard {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      min-width: 350px;
    }

    .s-card {
      margin-bottom: 1.5rem;
      width: 100%;
    }
  }
  