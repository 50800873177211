.hero, .hero-mid {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hero-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%; 
  max-width: 1200px; 
}

.hero-text {
  flex: 1;
  margin-top: 5%;
  padding-left: 6rem;
  padding-right: 3rem;
}

.hero-image-container {
  margin-top: 10%;
  background-color: #ffeddd;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Add shadow to image */
  padding: 10px;
  max-width: 400px;  
  flex-shrink: 1;
}

.heroImg {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); 
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px; 
}

.hero-text h1 {
  font-size: 4rem;
  margin-bottom: 10px;
  font-weight: 800;
  text-align: center;
  background: #ffeddd;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-text h2 {
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  background: #ffeddd;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.hero-text span {
  color: #ffeddd;
  font-size: 1.3rem;
  padding: 0.5rem 0 2rem 0;
}


.hero-text .show {
  text-decoration: none;
  background: #9EC1A3;
  padding: 1rem 2rem;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: bold;
  vertical-align: middle;
  letter-spacing: 2px;
  color: #ffeddd;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);  /* New shadow */
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease; /* New transition */
  cursor: pointer;  /* Indicates clickable area */
}

/* Hover effect for the button */
.hero-text .show:hover {
  background: #86b091; 
  transform: translateY(-3px);  /* Gives a subtle lift effect */
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.12);  /* Increase shadow on hover for depth */
}


.show .whatsapp-icon {
  font-size: 1.2rem;
  color: #ffeddd;
  vertical-align: middle; 
  margin-right: 8px;    
}

.typed-title-container {
  display: flex;
  justify-content: start;
  margin-top: 5%;
  margin-left: 25%;
}

.typed-title{
  margin-left: 5px;
  font-size: 1.5rem;
  font-weight: 400;
  background: #ffeddd;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(3px) brightness(0.8);
  object-position: 0% 30%;
  z-index: -1; 
}


.hero, .hero-mid {
  position: relative;
}

@media screen and (max-width: 555px) {

  .hero-image-container {
    display: none;
  }

  .hero-content {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .hero-text span {
    color: #ffeddd;
    font-size: 1.3rem;
    padding: 0;
  }

  .hero-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    margin-top: 30vh;
    text-align: center;
  }
  
  .hero-text h1 {
    text-align: center;
    font-size: 2.5rem;
  }

  .hero-text h2 {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
  }


  .typed-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
    margin-top: 1rem; 
  }

  .hero-text p {
    font-size: 1.1rem;
    padding: 0rem 0 2rem 0;
  }
  
  .hero-text .show {
    padding: 0.6rem 1.1rem;
    border-radius: 3px;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.6rem 1rem;
    margin-top: 2rem; 
  }
}
