@import url(https://fonts.googleapis.com/css2?family=Quattrocento+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.App {
  text-align: center;
}

.NavbarItems {
  /* background-color: #222; */
  background-color: #660000;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  width: 95%;
  height: 80px;
  border-radius: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  position: fixed;
  top: 20px;
  left: 50%;
  z-index: 9999;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

.navbar-logo {
  color: #ffcb99;
  font-size: 2rem;
  cursor: pointer;
  font-weight: 500;
  margin-left: 0 !important;
}

.navbar-logo-img {
  max-height: 70px;
  width: auto;
  object-fit: contain;
  margin-right: 10px;
}

.logo-container {
  display: flex;
  align-items: center; 
}

.fa-react {
  margin-left: 0.2rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
  text-align: center;
  justify-content: end;
}

.nav-links {
  color: #ffcb99;
  text-decoration: none;
  padding: 0.7rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
}

.nav-links i {
  padding-right: 10px;
}

.nav-links:hover {
  background-color: #ffcb99;
  color: #660000;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.active {
  text-decoration: none;
  padding: 0.7rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  background-color: #ffcb99;
  color: #660000;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.active i {
  padding-right: 10px;
}

button {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

button:hover {
  background-color: #151516;
  color: #fff;
}

.fa-bars {
  color: #222;
}

.nav-links-mobile {
  display: none;
}

.menu-icons {
  display: none;
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-container p {
  font-size: 0.6rem; /* Adjust as needed for the desired size */
  color: #ffcb99;   /* This will make it have the same color as the navbar logo */
  margin: 0;        /* Remove any default margins */
  padding-top: 4px;
  text-align: center; /* Give it a little space from the title above */
}

@media screen and (max-width: 850px) {

  .active {
    background: #ffcb99;
    transition: none;
    border-radius: 0;
    padding: 0.7rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    background-color: #ffcb99;
    color: #660000;
}

  .NavbarItems {
    z-index: 99;
  }

  .nav-menu {
    /* background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px); */
    
    border: 1px solid rgba(255, 255, 255, 0.18);
    background-color: #660000;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0px;
    left: -110%;
    opacity: 1;
    transition: all 0.3s ease;
    align-items: stretch;
    padding: 80px 0 30px 0;
    margin: 0;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.3s ease;
    z-index: -1;
  }

  .navbar-logo {
    color: #ffcb99;
    font-size: 1.7rem;
    cursor: pointer;
    font-weight: 500;
    margin-left: 0 !important;
  }
  
  .nav-links {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 1rem 0;
  }

  .nav-links:hover {
    background: #ffcb99;
    transition: none;
    border-radius: 0;
  }


  .menu-icons {
    display: block;
    position: absolute;
    top: 25px;
    right: 30px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: auto;
    border-radius: 4px;
    width: 80%;
    background: #01959a;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    letter-spacing: 2px;
    font-size: 1.2rem;
  }
  
  .fa-times {
    color: #ffeddd;
  }

  .fa-bars {
    color: #ffeddd
  }

  button {
    display: none;
  }
}

.hero, .hero-mid {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hero-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%; 
  max-width: 1200px; 
}

.hero-text {
  flex: 1 1;
  margin-top: 5%;
  padding-left: 6rem;
  padding-right: 3rem;
}

.hero-image-container {
  margin-top: 10%;
  background-color: #ffeddd;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Add shadow to image */
  padding: 10px;
  max-width: 400px;  
  flex-shrink: 1;
}

.heroImg {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); 
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px; 
}

.hero-text h1 {
  font-size: 4rem;
  margin-bottom: 10px;
  font-weight: 800;
  text-align: center;
  background: #ffeddd;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-text h2 {
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  background: #ffeddd;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.hero-text span {
  color: #ffeddd;
  font-size: 1.3rem;
  padding: 0.5rem 0 2rem 0;
}


.hero-text .show {
  text-decoration: none;
  background: #9EC1A3;
  padding: 1rem 2rem;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: bold;
  vertical-align: middle;
  letter-spacing: 2px;
  color: #ffeddd;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);  /* New shadow */
  transition: background-color 0.3s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease; /* New transition */
  cursor: pointer;  /* Indicates clickable area */
}

/* Hover effect for the button */
.hero-text .show:hover {
  background: #86b091; 
  -webkit-transform: translateY(-3px); 
          transform: translateY(-3px);  /* Gives a subtle lift effect */
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.12);  /* Increase shadow on hover for depth */
}


.show .whatsapp-icon {
  font-size: 1.2rem;
  color: #ffeddd;
  vertical-align: middle; 
  margin-right: 8px;    
}

.typed-title-container {
  display: flex;
  justify-content: start;
  margin-top: 5%;
  margin-left: 25%;
}

.typed-title{
  margin-left: 5px;
  font-size: 1.5rem;
  font-weight: 400;
  background: #ffeddd;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-filter: blur(3px) brightness(0.8);
          filter: blur(3px) brightness(0.8);
  object-position: 0% 30%;
  z-index: -1; 
}


.hero, .hero-mid {
  position: relative;
}

@media screen and (max-width: 555px) {

  .hero-image-container {
    display: none;
  }

  .hero-content {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .hero-text span {
    color: #ffeddd;
    font-size: 1.3rem;
    padding: 0;
  }

  .hero-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    margin-top: 30vh;
    text-align: center;
  }
  
  .hero-text h1 {
    text-align: center;
    font-size: 2.5rem;
  }

  .hero-text h2 {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
  }


  .typed-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
    margin-top: 1rem; 
  }

  .hero-text p {
    font-size: 1.1rem;
    padding: 0rem 0 2rem 0;
  }
  
  .hero-text .show {
    padding: 0.6rem 1.1rem;
    border-radius: 3px;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.6rem 1rem;
    margin-top: 2rem; 
  }
}

.footer {
  background: #660000;
  color: #ffcb99;
  padding: 1rem 6rem;
}

.top {
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: space-between;
}

.top p {
  font-size: 0.7rem;
  text-align: center;
}

.top i {
  color: #ffcb99;
  font-size: 2rem;
  margin-left: 1rem;
}

.top i:hover {
  color: #86b091 ;
}

.bottom {
  padding-top: 0.5rem;
  text-align: start;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bottom div {
  display: flex;
  flex-direction: column;
}

.bottom h4 {
  font-size: 1.3rem;
  padding: 1rem 0 0.8rem 0;
}

.logo{
  display: flex;
  align-items: center;
}

.logo-image {
  width: 150px;
}

.bottom a {
  text-decoration: none;
  color: #ffeddd ;
  padding-bottom: 0.4rem;
  font-size: 1.1rem;
}

@media screen and (max-width: 850px) {
  .footer {
    padding: 1rem 1.2rem;
  }

  .footer i {
    margin: 1rem 1rem 0 0;
  }

  .bottom div {
    width: 100%;
  }

  .logo{
    display: flex;
  }
  
  .logo-image {
    width: 80px;
  }

  .top {
    flex-direction: column;
  }
  
}

.trip {
  margin: 4rem 6rem;
  color: #2a2a2a;
}

.trip h1 {
  font-size: 3rem;
  color: #660000;
}

.looking-link {
  font-size: 1.5rem;
  color: #660000;
}

.tripcard {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}

.t-card {
  display: flex;
  flex-direction: column;
  width: 32%;
  text-align: center;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
  border-radius: 7px;
  padding: 1rem 0.8rem;
  margin-bottom: 5%;
}

.t-image {
  height: 100%;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  border-radius: 7px;
}

.t-image img {
  width: 100px;
  height: 100px;
  border-radius: 7px;
  transition: 0.3s ease-in-out;
}

.t-card:hover img {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  overflow: hidden;
}

.t-card h4 {
  font-size: 1.3rem;
  padding: 0.9rem 0 0.2rem 0;
}

.t-card .show {
  display: flex;
  margin-top: 10%;
  justify-content: center;
  text-decoration: none;
  background: #9EC1A3;
  padding: 1rem 2rem;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: bold;
  vertical-align: middle;
  letter-spacing: 2px;
  color: #ffeddd;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);  /* New shadow */
  transition: background-color 0.3s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease; /* New transition */
  cursor: pointer;  /* Indicates clickable area */
}

/* Hover effect for the button */
.t-card .show:hover {
  background: #86b091; 
  -webkit-transform: translateY(-3px); 
          transform: translateY(-3px);  /* Gives a subtle lift effect */
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.12);  /* Increase shadow on hover for depth */
}


.show .whatsapp-icon {
  font-size: 1.2rem;
  color: #ffeddd;
  vertical-align: middle; 
  margin-right: 8px;    
}

.texts {
  display: flex;
  margin-top: 10px;
  height: 100%;
  max-width: 400px;
  flex-direction: column;
  align-items: stretch;
  padding-left: 25px; 
  padding-right: 25px;
  text-align: center;
}


@media screen and (max-width: 850px) {
  .trip {
    margin: 4rem 2rem;
  }
  .tripcard {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
  }
  .t-card {
    margin-bottom: 1.5rem;
    width: 100%;
  }
}

.service {
    margin: 4rem 6rem;
    color: #2a2a2a;
  }
  
  .service h1 {
    font-size: 3rem;
    color: #660000;
  }
  
  .servicecard {
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  
  .s-card {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(33.3333% - 1.5rem);
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    text-align: center;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
    padding: 1.5rem 1rem;
  }
  
  .s-image {
    height: 50%;
    justify-content: center;
    align-items: stretch;
    overflow: hidden;
    border-radius: 7px;
  }
  
  .s-image img {
    width: 100px;
    height: 100px;
    border-radius: 7px;
    transition: 0.3s ease-in-out;
  }
  
  .s-card h4 {
    font-size: 1.3rem;
    margin-bottom: 10px;
    padding: 0.9rem 0 0.2rem 0;
  }
  
  .s-card .show {
    display: flex;
    margin-top: 10%;
    justify-content: center;
    text-decoration: none;
    background: #9EC1A3;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    vertical-align: middle;
    letter-spacing: 2px;
    color: #ffeddd;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);  /* New shadow */
    transition: background-color 0.3s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease; /* New transition */
    cursor: pointer;  /* Indicates clickable area */
  }
  
  /* Hover effect for the button */
  .s-card .show:hover {
    background: #86b091; 
    -webkit-transform: translateY(-3px); 
            transform: translateY(-3px);  /* Gives a subtle lift effect */
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.12);  /* Increase shadow on hover for depth */
  }
  
  
  .show .whatsapp-icon {
    font-size: 1.2rem;
    color: #ffeddd;
    vertical-align: middle; 
    margin-right: 8px;    
  }
  
  .texts {
    display: flex;
    margin-top: 10px;
    height: 100%;
    max-width: 400px;
    text-align: center;
  }

  .texts p {
    text-indent: 8px;
  }

  .workDiv {
    margin: 4rem 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #2a2a2a;
  }

.workCard {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  max-width: 800px;
}

  @media screen and (max-width: 850px) {
    .service {
      margin: 4rem 2rem;
    }
    .servicecard {
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
    }

    .workCard {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      min-width: 350px;
    }

    .s-card {
      margin-bottom: 1.5rem;
      width: 100%;
    }
  }
  
.route {
    width: 100%;
    height: 100vh;
    position: relative;
  }
  .route-mid {
    width: 100%;
    height: 70vh;
    position: relative;
  }
  
  .route-mid h1 {
    padding-top: 4rem !important;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .routeImg{
    display: flex;
    width: 100vw;
    height: 70vh; 
    object-fit: cover;
    object-position: 50% 60%;
    -webkit-filter: blur(3px) brightness(0.8);
            filter: blur(3px) brightness(0.8);
  }

  .route-text {
    position: absolute;
    top: 55%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
  }
  
  .route-text h1 {
    font-size: 2.5rem;
    font-weight: 800;
    background: #ffeddd ;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .route-text p {
    color: #f4f4f4;
    font-size: 1.3rem;
    padding: 0.5rem 0 2rem 0;
  }
  
  .route-text .show {
    text-decoration: none;
    background: #fff;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: #222;
  }
  
  .route-text .hide {
    display: none;
  }
  
  @media screen and (max-width: 555px) {
    .route-text h1 {
      padding: 10px 20px;
    }
    .route-text p {
      font-size: 1.1rem;
      padding: 0rem 0 2rem 0;
    }
    .route-text .show {
      padding: 0.6rem 1.1rem;
      border-radius: 3px;
      font-size: 1rem;
      font-weight: bold;
    }
  }
  
.office {
    margin: 4rem 6rem;
    color: #2a2a2a;
  }
  
  .office h1 {
    font-size: 3rem;
    color: #660000
  }
  
  .officecard {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  
  .o-card {
    width: 32%;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem 0.8rem;
  }
  .o-image {
    height: 200px;
    overflow: hidden;
    border-radius: 7px;
  }
  
  .o-image img {
    width: 100%;
    height: 100%;
    border-radius: 7px;
    transition: 0.3s ease-in-out;
    display: block; /* removes any default vertical space */
    margin: 0 auto; 
  }
  
  .o-card:hover img {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    overflow: hidden;
  }
  
  .o-card h4 {
    font-size: 1.3rem;
    padding: 0.9rem 0 0.2rem 0;
  }
  
  hr.rounded {
    border-top: 1px solid #bebebe;
    border-radius: 5px;
    width: 80%;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
    margin: auto;
    margin-top: 80px;
  }
  
  @media screen and (max-width: 850px) {
    .office {
      margin: 4rem 2rem;
    }
    .officecard {
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
    }
    .o-card {
      margin-bottom: 1.5rem;
      width: 100%;
    }
  }
  
.destination {
  margin: 4rem 6rem;
  color: #2a2a2a;
}

.destination h1 {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #660000;
}

.highlighted-box {
  position: relative; /* this is crucial for the absolutely positioned child */
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 60px;
  background-color: #fff;       /* to contain floated or absolutely positioned children */
}

.first-des {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4.5rem;
}

.first-des-reverse {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: 6.5rem;
}

.des-text {
  width: 45%;
  text-align: justify;
  font-size: 1.1rem;
}

.des-text h2 {
  padding-bottom: 1rem;
  color: #660000;
  font-weight: 400;
  background-color: #ffeddd; 
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 30px;
  width: 50%;
}

.first-text {
  margin-bottom: 20px;
}

.image {
  width: 45%;
  display: flex;
  justify-content: space-between;
}

.image img {
  position: relative;
  width: 100%;
  height: 380px;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.25);
}

.picture-2 {
  position: absolute;
  top: -5%;
  right: 0;
  z-index: 1;
}

.social-icons {
  margin-top: 40px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
}

.social-icons a {
  color: #660000; /* default icon color */
  transition: color 0.3s ease; 
}

.social-icons a:hover {
  color: #0077b5; /* change to desired hover color */
}

@media screen and (max-width: 850px) {
  .destination {
    margin: 4rem 1rem;
  }
  .first-des {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 2.5rem;
  }

  .first-des-reverse {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 2.5rem;
  }

  .des-text {
    width: 100%;
  }

  .des-text h2 {
    width: 100%;
    padding: 10px 6px;
    text-align: center;
  }

  .image {
    width: 100%;
    margin: 1.8rem 0;
  }

  .image img {
    height: 250px;
  }

  .picture-2 {
    margin-left: 10px;
  }

  .highlighted-box {
    padding: 40px;
  }
}

.form-container {
  margin: 4rem 6rem;
  display: flex;
  width: 35%;
}

.title {
  margin-top: 50px;
  color: #660000
}

.form-container form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-container button {
  display: inline;
}

.contact-section {
  display: flex;
  grid-gap: 20px;
  gap: 20px; 
  margin-top: 50px;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center; 
}


.map-container {
  position: relative; 
  padding: 1rem; 
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.address-details {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-buttons {
  margin-top: 1rem;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem; 
}

.whatsapp-btn, .call-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.whatsapp-btn {
  display: inline-block; 
  background-color: #25D366; 
  color: white;
  text-decoration: none;  
}

.call-btn {
  display: inline-block; 
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #34b7f1;
  color: white;
  text-decoration: none;  
}

.call-btn:hover {
  background-color: #007BBD;
}

.whatsapp-btn:hover {
  background-color: #128C53; 
}

.call-btn:hover {
  background-color: #007BBD;
}


@media screen and (max-width: 850px) {
  .title {
    margin-top: 50px;
    color: #660000;
    font-size: x-large;
    width: 100%;
    text-align: center;
  }
  
  .form-container {
    margin: 1rem 6rem;
    padding: 1rem;  
    width: 100%;
  }

  .form-container form {
    width: 100%;
  }

  .contact-section {
    flex-direction: column;
    margin-top: 0;
    grid-gap: 2rem;
    gap: 2rem;
  }

  .map-container {
    width: calc(100% - 2rem);
    padding: 1rem;
  }

  .map-container iframe {
    width: 100%; 
    height: 100%; 
  }

  .address-details p {
    text-align: center; 
    padding: 0 1rem; 
  }

  .contact-buttons {
    flex-direction: column; 
    grid-gap: 0.5rem; 
    gap: 0.5rem; 
    align-items: center; 
  }
}

