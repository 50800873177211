.form-container {
  margin: 4rem 6rem;
  display: flex;
  width: 35%;
}

.title {
  margin-top: 50px;
  color: #660000
}

.form-container form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-container button {
  display: inline;
}

.contact-section {
  display: flex;
  gap: 20px; 
  margin-top: 50px;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center; 
}


.map-container {
  position: relative; 
  padding: 1rem; 
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.address-details {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-buttons {
  margin-top: 1rem;
  display: flex;
  gap: 1rem; 
}

.whatsapp-btn, .call-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.whatsapp-btn {
  display: inline-block; 
  background-color: #25D366; 
  color: white;
  text-decoration: none;  
}

.call-btn {
  display: inline-block; 
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #34b7f1;
  color: white;
  text-decoration: none;  
}

.call-btn:hover {
  background-color: #007BBD;
}

.whatsapp-btn:hover {
  background-color: #128C53; 
}

.call-btn:hover {
  background-color: #007BBD;
}


@media screen and (max-width: 850px) {
  .title {
    margin-top: 50px;
    color: #660000;
    font-size: x-large;
    width: 100%;
    text-align: center;
  }
  
  .form-container {
    margin: 1rem 6rem;
    padding: 1rem;  
    width: 100%;
  }

  .form-container form {
    width: 100%;
  }

  .contact-section {
    flex-direction: column;
    margin-top: 0;
    gap: 2rem;
  }

  .map-container {
    width: calc(100% - 2rem);
    padding: 1rem;
  }

  .map-container iframe {
    width: 100%; 
    height: 100%; 
  }

  .address-details p {
    text-align: center; 
    padding: 0 1rem; 
  }

  .contact-buttons {
    flex-direction: column; 
    gap: 0.5rem; 
    align-items: center; 
  }
}
